
import {
  defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import Loader from '@/components/ui/Loader.vue';
import useCourseSession from '@/domains/course/composables/useCourseSession';
import { Names } from '@/plugins/vue-router';
import { AtomType } from '@/domains/atom';

export default defineComponent({
  name: 'AssignmentTrackGateway',

  components: {
    Loader,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { playerSessionId } = toRefs(props);

    const router = useRouter();

    const {
      fetch,
      trackSession,
      isSomeStepCompleted,
      isSessionCompleted,
    } = useCourseSession({ trackSessionId: playerSessionId });

    onMounted(async () => {
      await fetch();

      if (!trackSession.value) return;

      const getRouteName = () => {
        if (isSessionCompleted.value) {
          return Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW;
        }

        if (isSomeStepCompleted.value) {
          return Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_STEP;
        }

        return Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW;
      };

      await router.replace({
        name: getRouteName(),
        params: {
          // NOTE: Нужно для R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW (AssignmentAtomPreview)
          atomType: AtomType.TRACK,
          // NOTE: Нужно в обоих вариантах
          playerSessionId: String(playerSessionId.value),
          // NOTE: для R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_STEP stepId будет получен в на месте
        },
      });
    });
  },
});
