import { computed, Ref, ref } from 'vue';
import { trackSessionGet } from '@/services/api/tracks';
import {
  ICourse,
  ICourseAssignment,
  ICourseAtom,
  ICourseHierarchy,
  ICourseSection,
  ICourseSession,
} from '@/domains/course';
import { TrackSessionStatus } from '@/domains/track';
import { useDayJs } from '@/plugins/dayjs/composables';

interface IUseCourseSessionProps {
  trackSessionId: Ref<number>;
}

export type IUseCourseSessionState = ReturnType<typeof useCourseSession>

export default function useCourseSession({ trackSessionId }: IUseCourseSessionProps) {
  const dayJs = useDayJs();
  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);

  const course = ref<ICourse>();
  const order = ref<ICourseHierarchy[]>([]);
  const steps = ref<ICourseAtom[]>([]);
  const sections = ref<ICourseSection[]>([]);
  const trackSession = ref<ICourseSession>();
  const assignment = ref<ICourseAssignment>();

  const fetch = async () => {
    isLoading.value = true;
    isError.value = false;

    try {
      const response = await trackSessionGet({ trackSessionId: trackSessionId.value });

      course.value = response.track;
      order.value = response.order;
      steps.value = response.steps;
      assignment.value = response.assignment;
      trackSession.value = response.trackSession;
      sections.value = response.sections;
    } catch (e: unknown) {
      isError.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  const isSessionCompleted = computed(() => trackSession.value?.trackSessionStatus === TrackSessionStatus.COMPLETED);
  const isSessionActive = computed(() => trackSession.value?.trackSessionStatus === TrackSessionStatus.ACTIVE);
  const isSessionStarted = computed(() => isSessionCompleted.value || isSessionActive.value);
  const isSomeStepCompleted = computed(() => Boolean(trackSession.value?.finishedStepsCount));

  const isSessionExpires = computed(() => {
    const today = dayJs();
    const deadline = dayJs(assignment.value?.finishDate);
    return deadline.diff(today, 'week') < 1;
  });
  const isSessionFailed = computed(() => {
    const today = dayJs();
    const deadline = dayJs(assignment.value?.finishDate);
    return !isSessionCompleted.value && today.isAfter(deadline, 'days');
  });

  const hasFinishDate = computed(() => Boolean(assignment.value?.finishDate));
  const assignmentSessionId = computed(() => Number(assignment.value?.assignmentSessionId));
  const increaseFinishedStepsCount = () => {
    if (trackSession.value) {
      trackSession.value.finishedStepsCount += 1;
    }
  };

  return {
    isError,
    isLoading,
    course,
    steps,
    sections,
    trackSession,
    assignment,
    hierarchy: order,
    trackSessionId,
    assignmentSessionId,
    hasFinishDate,
    isSessionStarted,
    isSessionActive,
    isSessionCompleted,
    isSessionExpires,
    isSessionFailed,
    isSomeStepCompleted,
    fetch,
    increaseFinishedStepsCount,
  };
}
